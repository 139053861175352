import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import iot from "../../images/article2.jpg";
import ux from "../../images/article3.jpg";
import flash from "../../images/article1.jpg";
import mapp from "../../images/mobile-app-dev.jpg";
import data from "../../images/data-an.jpg";
import sms from "../../images/sms.jpg";
import ai from "../../images/AI.png";
import cloud from "../../images/cloud-investment.png";
import ContactUs from "../../components/ContactUs";
import NewsBox from "../../components/blog/NewsBox";
import IntroBanner from "../../components/IntroBanner";
import bannerImg from "../../images/blog-header.jpg";
import microApps from "../../images/micro-apps-banner.jpeg";
import FlutterNativeImg from "../../images/blog/working-with-native-android-sdks-from-flutter/flutter-cover-img.png";
import chamil from "../../images/news12.jpg";
import QATeam from "../../images/news11.png";

const Blog = (props) => {
  // list of blogs
  let newsBoxes = [
    {
      image: chamil,
      url: "/blog/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone",
      title: "New Addition to Salesforce MVP Hall of Fame Sets Global Milestone",
      tags: "tag1, tag2",
      description:
        "Chamil Madusanka, Head of Salesforce Practice at Australian-based IT firm iTelaSoft, has become the first ever Sri Lankan to be inducted [...]",
    },    
    {
      image: QATeam,
      url: "/blog/iTelaSoft-levels-up-software-quality-with-ISTQB-Platinum-Partnership",
      title: "iTelaSoft levels up Software Quality with ISTQB Platinum Partnership",
      tags: "tag1, tag2",
      description:
        "In a significant milestone, iTelaSoft has achieved the esteemed ISTQB Platinum Partnership status. This recognition, granted by the [...]",
    },
    {
      image: cloud,
      url: "/blog/maximising-cloud-investment-a-guide-for-mid-size-companies",
      title: "Maximising Cloud Investment: A Guide for Mid-Size Companies",
      tags: "tag1, tag2",
      description:
        "In today's fast-paced digital landscape, businesses are increasingly relying on cloud computing to enhance flexibility, scalability, and [...]",
    },
    {
      image: ai,
      url: "/blog/a-guide-to-seamless-AI-integration-into-your-existin-gsystems",
      title: "A Guide to Seamless AI Integration into Your Existing Systems",
      tags: "tag1, tag2",
      description:
        "As businesses seek to stay ahead in an increasingly competitive landscape, the integration of artificial intelligence (AI)[...]",
    },
    {
      image: FlutterNativeImg,
      url: "/blog/working-with-native-android-sdks-from-flutter",
      title: "Working with Native Android SDKs from Flutter",
      tags: "tag1, tag2",
      description:
        "One of our clients in the automotive industry came to us looking to build a mobile app. Their business spans across several areas such as  [...]",
    },
    {
      image: microApps,
      url: "/blog/embeddable-micro-apps-go-live",
      title: "Building Embeddable Apps with Web Components",
      tags: "tag1, tag2",
      description:
        "We had a recent request from one our clients to build some features into their product. The catch was these had to be made available on [...]",
    },
    // {
    //   image: sms,
    //   url: "/blog/sms-bot-leveling-up-customer-service",
    //   title: "SMS Bot - Leveling Up Customer Service",
    //   description:
    //     "Chatbots—consumers love them; they’re more convenient, less invasive. Organisations today see their value as well; these tools help them save time and money while they cater to the needs of their customers. [...]",
    // },
    // {
    //   image: mapp,
    //   url: "/blog/why-businesses-should-invest-in-mobile-app-development",
    //   title: "Why Businesses Should Invest in Mobile App Development",
    //   description:
    //     "There’s an app for just about everything today. Indeed, app developers have maximised mobile devices and smartphones’ technological advances to develop innovative apps that have [...]",
    // },
    {
      image: iot,
      url: "/blog/testing-narrowband-iot-deployments",
      title: "Testing Narrowband IoT Deployments",
      tags: "tag1, tag2",
      description:
        "The last 12 months have been an interesting period for our iTelaSoft IoT team, especially with some dominant changes taking place in the mobile service providers space. Aroundthe world [...]",
    },
    {
      image: ux,
      url: "/blog/getting-started-as-a-ux-intern",
      title: " Getting started as a UX intern",
      tags: "tag1, tag2",
      description:
        "Whether you are an undergraduate searching for an internship for the first time, or someone who is already working in another career path, you may have heard about the field of User Experience [...]",
    },
    {
      image: flash,
      url: "/blog/saving-treasures-in-drowning-flash",
      title: "Saving Treasures in Drowning Flash",
      tags: "tag1, tag2",
      description:
        "The fate of Flash was sealed after Steve Jobs figured Flash is straining the tiny processors in the first-generation iOS devices and mercilessly draining precious battery power [...]",
    }
    // {
    //   image: data,
    //   url: "/blog/trusting-numbers-and-figures-the-value-of-data-analytics",
    //   title: "Trusting Numbers and Figures - The Value of Data Analytics",
    //   description:
    //     "There’s an app for just about everything today. Indeed, app developers have maximised mobile devices and smartphones’ technological advances to develop innovative apps [...]",
    // },
  ];

  return (
    <Layout bodyClass="page-noteworthy">
      <Seo
        title="Blog"
        description="Read our tech related articles and blog posts."
      />

      <IntroBanner
        image={bannerImg}
        title="iTelaSoft Blog"
        subtitle="Read our tech related articles and blog posts."
        type="page"
      />

      <div className="reacent-post">
        <div className="title-posts">Recent Posts</div>
        <hr />
      </div>
      <div className="content">
        <div className="container py-6">
          <div className="row">
            {newsBoxes.map((newsBox) => (
              <div className="col-12 col-md-6">
                <NewsBox
                  image={newsBox.image}
                  url={newsBox.url}
                  title={newsBox.title}
                  tags={newsBox.tags}
                  description={newsBox.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "blog-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Blog;
