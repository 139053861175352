import React from "react";
const IntroBanner = (props) => {
  return (
    <div className="intro intro-banner" id="up">
      <div className="intro-banner-img">
        <img src={props.image} alt="intro banner image" />
      </div>
      {props.type === "page" ? (
        <div className="container">
          <div className="row main-page">
            <div className="col-12">
              <h1 className="page-title">{props.title}</h1>
              <h2 className="page-subtitle">{props.subtitle}</h2>
            </div>
          </div>
        </div>
      ) : (
        <div className="container container-narrow intro-title blog-title">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">{props.title}</h1>
              <span>{props.by}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntroBanner;
