import React from "react";
import { Link } from "gatsby";

const NewsBox = (props) => {
  return (
    <div className="card news-box">
      <Link className="wr-card-img" to={props.url}>
        <img className="card-img-top" src={props.image} alt="Map" />
      </Link>
      <div className="card-body">
        <Link className="card-title" to={props.url}>
          {props.title}
        </Link>
        <div className="card-tags">{props.tags}</div>
        <p className="card-text">{props.description}</p>
        <a className="btn-link read-more" href={props.url} target="_blank">
          Continue Reading
        </a>
      </div>
    </div>
  );
};

export default NewsBox;
