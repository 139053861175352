// import React from 'react'
import React, { Component } from 'react';
import comment from '../images/comment.png';

export default class ContactUs extends Component {
    render() {
        return (
            <div className="container mt-7 mb-5">
                <div className="row text-center">
                    <div className="col-12 col-md-3 m-auto"> <img className="img-fluid comment-ic al" alt="contact us" src={comment} /></div>
                    <div className="col-12 col-md-6 text-center">
                        <div className="title-1 pb-1">Contact us for a free consultation</div>
                        <p className="para-contact-us">Let us discuss how we can help you.</p>
                    </div>
                    <div className="col-12 col-md-3 m-auto"> <a className="btn btn-primary" href="/contact/">CONTACT US</a></div>
                </div>
            </div>
        )
    }
}